<template>
  <div
    class="fill-height">
    <v-dialog
      v-model="dialog.detail"
      fullscreen
      scrollable
    >
    <v-card>
      <v-card-title>
        <span>Detail Struktur</span>
        <v-spacer></v-spacer>
        <v-btn
          :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
          :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
          color="white"
          elevation="0"
          fab
          class="text-capitalize px-0"
          @click="dialog.detail = false; detail = false">
          <v-icon size="25" class="color-default">mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
    <section>
      <div>
        <div>
          <div class="mt-4">
            <v-expansion-panels flat class="mb-3" multiple v-model="openList">
              <v-expansion-panel class="mb-3" v-for="(item, i) in list" :key="i">
                <v-expansion-panel-header :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `background: #f6f6f6; min-height: 55px; padding: 17px 20px; height: 55px;` : `background: #f6f6f6; min-height: 55px; padding: 17px 20px; height: auto;`">
                  <template v-slot:actions>
                    <div>
                      <v-icon class="px-0" style="order: 0;" color="#7B7B7B">
                        $expand
                      </v-icon>
                    </div>
                  </template>
                  <v-list dense style="order: 1;" class="py-0 pl-6" color="transparent">
                    <v-list-item class="py-0 px-0">
                      <div class="d-flex align-center" style="width: 100%;">
                        <div class="ml-0 font-weight-bold text-capitalize" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'body-2'">
                          {{ item.title }}
                        </div>
                        <div class="body-2 text-first ml-auto">
                          <!-- 11 lectures - 26min -->
                        </div>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="non-padding-expansion">
                  <v-card flat class="px-3">
                    <v-card-title class="px-0">
                      <div>
                        <div>
                          Daftar Pejabat {{ item.title }}
                        </div>
  
                        <div class="mb-5">
                          <a
                            class="body-1"
                            :href="item.website"
                            target="_blank"
                            :style="`color: ${set_color}`"
                            style="opacity: 60%;">
                            <u>{{ item.website }}</u>
                          </a>
                        </div>
                      </div>
                      <v-spacer></v-spacer>
                      <!-- <v-text-field
                        v-model="search"
                        solo
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        ></v-text-field> -->
                        <a 
                        :href="`https://www.google.com/maps/search/?api=1&query=${item.latitude},${item.longitude}`"
                        style="color:#0000FF;text-align:left" 
                        target="_blank">
                          <iframe 
                            width="300" 
                            height="170" 
                            frameborder="0" 
                            scrolling="no" 
                            marginheight="0" 
                            marginwidth="0" 
                            :src="`https://maps.google.com/maps?q=${item.latitude},${item.longitude}&hl=es&z=14&amp;output=embed`"
                          >
                          </iframe>
                        </a>
                      </v-card-title>
                    <!-- <v-data-table
                      :headers="headers"
                      :items="desserts"
                      :search="search"
                    ></v-data-table> -->
                    <div style="height: 200px; display: flex; width: 100%; justify-content: center; align-items: center;">
                      Data Belum Tersedia
                    </div>
                  </v-card>

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>

    </section>
    </v-card-text>
    </v-card>
    </v-dialog>

    <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
      Struktur Organisasi
    </div>

    <v-card
      flat>
      <v-card-text :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'px-0'">
        <div>
          <v-skeleton-loader
            v-if="process.load"
            :loading="process.load"
            transition="scale-transition"
            :tile="false"
            class="ma-auto load_card_x_large"
            type="image"
            style="width: 100% !important; height: 60vh !important; min-height: 60vh !important;">
          </v-skeleton-loader>
        </div>

        <div v-if="!process.load">
          <!-- <div class="d-flex">
            <div style="width: 100%;">
              Untuk melihat detail klik tombol detail atau klik gambar
            </div>
            <div class="d-flex">
              <v-btn
                class="mx-2"
                height="46"
                style="border-radius: 6px;"
                dark
                @click="type= 'list'; dialog.detail = true"
                small
                :color="set_color"
                elevation="0"
              >
                <v-icon size="20" dark class="mr-2">mdi-text-box-search-outline</v-icon>
                Detail
              </v-btn>
            </div>
          </div> -->
          <div class="mt-10">
            <v-img :src="require('@/assets/image/bagan.png')" :lazy-src="require('@/assets/image/bagan.png')"
              class="grey lighten-2 cursor-pointer"
              @click="type= 'list'; dialog.detail = true"

              >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                  >
                  <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          
          <!-- <v-row class="justify-center mt-4">
            <v-col cols="12" md="3">
              <v-btn
                @click="dialog.detail = true"
                elevation="0"
                :color="`${set_color}`"
                class="text-capitalize white--text button-link-transparent">
                Sekretariat daerah
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="dialog.detail = true"
                elevation="0"
                :color="`${set_color}`"
                class="text-capitalize white--text button-link-transparent">
                Sekretariat DPRD
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="dialog.detail = true"
                elevation="0"
                :color="`${set_color}`"
                class="text-capitalize white--text button-link-transparent">
                Inspektorat Daerah
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="dialog.detail = true"
                elevation="0"
                :color="`${set_color}`"
                class="text-capitalize white--text button-link-transparent">
                Kecamatan
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="dialog.detail = true"
                elevation="0"
                :color="`${set_color}`"
                class="text-capitalize white--text button-link-transparent">
                Dinas Daerah
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                @click="dialog.detail = true"
                elevation="0"
                :color="`${set_color}`"
                class="text-capitalize white--text button-link-transparent">
                Badan Daerah
              </v-btn>
            </v-col>
          </v-row> -->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { createSEOMeta } from '@/utils/seo'
import VueTree from "@ssthouse/vue-tree-chart";
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";

export default {
  name: "mind-map",
  components: { VueTree, OrganizationChart },
  data() {
    return {
      openList: 0,
      detail : false,
      type: 'bagan',
      dialog: {
          detail: false
        },
        search: '',
        headers: [
          {
            text: 'Dessert (100g serving)',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Calories', value: 'calories' },
          { text: 'Fat (g)', value: 'fat' },
          { text: 'Carbs (g)', value: 'carbs' },
          { text: 'Protein (g)', value: 'protein' },
          { text: 'Iron (%)', value: 'iron' },
        ],
        desserts: [
          {
            name: 'Frozen Yogurt',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: 1,
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: 1,
          },
          {
            name: 'Eclair',
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: 7,
          },
          {
            name: 'Cupcake',
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: 8,
          },
          {
            name: 'Gingerbread',
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: 16,
          },
          {
            name: 'Jelly bean',
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: 0,
          },
          {
            name: 'Lollipop',
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: 2,
          },
          {
            name: 'Honeycomb',
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: 45,
          },
          {
            name: 'Donut',
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: 22,
          },
          {
            name: 'KitKat',
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: 6,
          },
        ],
        list: [],
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=struktur',
          title: 'Struktur Organisasi',
          description: 'Struktur Organisasi Pejabat Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
    };
  },
  head () {
    return {
      title: this.content.title,
      meta: createSEOMeta(this.content)
    }
  },
  computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true
        let params = {
          limit: 1000000
        }
        await this.$axios.$get(`${process.env.API}sites/profile/unit`, { params })
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.list = res.results.data

              this.process.load = false
          }
        })
      }
    }
};
</script>

<style>
.mind-map-layout {
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
}

.tree-container > svg[data-v-56c15873], .tree-container .dom-container[data-v-56c15873] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50% !important;
    top: 0;
    overflow: visible;
    transform-origin: 0 50%;
}
.tree-container[data-v-56c15873] {
    position: relative;
    overflow: scroll !important;
}

.orgchart .node {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 3px;
    border: 2px dashed transparent;
    text-align: center;
    width: auto !important;
}

.orgchart-container {
    position: relative;
    display: inline-block;
    height: 420px;
    width: calc(100% - 24px);
    border: none !important;
    border-radius: 5px;
    overflow: auto;
    text-align: center;
}
</style>
