<template>
  <v-app>
    <MobileHeader
      v-if="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm') && !loader" />
    
    <MobileFooter
      v-if="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm') && !loader" />

    <DesktopHeader v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'  && !loader && $route.name !== 'display'"/>

    <v-sheet 
      light
      :style="$route.name === 'beranda' || $route.name === 'display' ? $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'background: #fff;' :  'background: #f9f9f9;' : 'background: #f9f9f9;'"
      min-height="100%">
      <Loader v-if="loader" />
      
      <nuxt v-else />
    </v-sheet>
    
    <DesktopFooter
      v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && !loader  && $route.name !== 'display'" />

    <v-fab-transition
      class="desktop">
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        elevation="3"
        fab
        dark
        fixed
        bottom
        right
        :style="`background-color: ${$store.state.header.config.color}`"
        @click="$vuetify.goTo('#app', { duration: 500, offset: 0 })">
        <v-icon large>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>   
    <script src="https://website-widgets.pages.dev/dist/sienna.min.js" defer></script>
    <script src="https://code.responsivevoice.org/responsivevoice.js?key=SBdKghdA"></script>     
  </v-app>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined

  import Loader from "~/components/Loader.vue"

  import MobileHeader from "~/components/public/mobile/Header.vue"
  import DesktopHeader from "~/components/public/desktop/Header.vue"
  import MobileFooter from "~/components/public/mobile/Footer.vue"
  import DesktopFooter from "~/components/public/desktop/Footer.vue"
  
  import firebase from "firebase/app"
  import "firebase/messaging"
  export default {
    data () {
      return {
        loader: true,
        fab: false
      }
    },
    components: {
      Loader,
      MobileHeader,
      MobileFooter,
      DesktopHeader,
      DesktopFooter
    },
    mounted () {
      this.fetch()
      // this.loader = false
    },
    head: {
        // script: [
        // { src: 'https://cdn.userway.org/widget.js', 'data-account': 'PhdjdZA0Qn'}
        // ]
    },
    beforeCreate() {
      this.loader = true
    },
    created() {
      this.requestPermission()
    },
    methods: {
      async fetch () {
        await this.$axios.$get(`${process.env.API}sites/config`)
        .then(response => {
          let res = response

          res.results.data[0].color = JSON.parse(res.results.data[0].color)
          

          this.$store.commit('header/config/setDuration', (parseInt(res.results.data[0].banner_duration))*1000)

          if (res.results.data[0].color.length > 0) {
            res.results.data[0].color.map((obj) => {
              if (obj.active) {
                this.$store.commit('header/config/setColor', obj.hex)
              }
              
            })
          } else {
            this.$store.commit('header/config/setColor', '#a20000')
          }
          this.loader = false
        })
      },
      onScroll (e) {
        if (typeof window === 'undefined') return

        const top = window.pageYOffset || e.target.scrollTop || 0

        this.fab = top > window.innerHeight-100
      },
      requestPermission () {
        try {
          firebase
            .messaging()
            .requestPermission()
            .then(() => {
              console.log("Notification permission granted")

              firebase
                .messaging()
                .getToken()
                .then((token) => {
                  window.console.log(token)

                  this.$store.commit('member/auth/setFcm', token)
                  Cookie.set('fcm', token)

                  this.receiveMessage()
                })
            })
            .catch((err) => {
              console.log("Unable to get token ", err)
            })
        } catch (e) {
          console.log(e)
        }
      },
      receiveMessage () {
        try {
          firebase
            .messaging()
            .onMessage((payload) => {
              console.log("payload", payload)
            })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
