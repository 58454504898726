<template>
  <div>
    <!-- <v-app-bar
      app
      fixed
      class="px-2"
      :class="$route.name === '/' || $route.name === 'beranda' ? 'header' : ''"
      elevation="0"
      :prominent="false"
      :hide-on-scroll="false"
      scroll-target="#scrolling-techniques-4"
      :style="`background-color: ${set_color}`"
      style="z-index: 3; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
      <v-container>
        <v-row>
          <v-col 
            class="d-flex align-center">
            <div>
              <nuxt-link
                to="/beranda"
                class="d-flex align-center"
                style="opacity: 60%;">
                <v-img 
                  contain
                  width="120"
                  height="auto"
                  alt="Kabupaten Klaten" 
                  :src="require('@/assets/logo/favicon.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>

            <v-spacer />

              <div 
                  v-for="(item, index) in menu"
                  :key="index">
  
                  <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.sub === undefined">
                    <v-btn
                      :to="item.link"
                      text
                      class="body-2 text-capitalize px-3 ml-2"
                      :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                      :style="$route.path.split('/')[1] === item.link.split('/')[1] ? 'color: #FFFFFF;' : 'color: #FFFFFF;'"
                      style="border-radius: 50px;">
                      {{ item.title }}
                    </v-btn>
                  </div>
      
                  <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.sub !== undefined">
                    <v-menu offset-y open-on-hover>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          v-bind="attrs"
                          v-on="on"
                          class="body-2 text-capitalize px-3 ml-2"
                          :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                          :style="$route.path.split('/')[1] === item.link.split('/')[1] ? 'color: #FFFFFF;' : 'color: #FFFFFF;'"
                          style="border-radius: 50px;">
                          {{ item.title }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card min-width="280">
                        <v-list>
                          <v-list-item
                            v-for="(i, m) in item.sub"
                            :key="m"
                          >
                            <v-list-item-title>
                              <v-hover v-slot="{ hover }">
                                <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                  <div
                                    style="color: rgba(0, 0, 0, 0.87)"
                                    @click="toList(i.link)"
                                    :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                    {{ i.title }}
                                  </div>
                                </div>
                              </v-hover>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
              </div>

          </v-col>
        </v-row>
      </v-container>
    </v-app-bar> -->

    <v-navigation-drawer
      v-model="drawer"
      fixed
      app
      temporary
    >
      <!-- <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>John Leider</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider> -->

      <v-list>
        <div 
            v-for="(item, index) in menu"
            :key="index">
            <v-list-item 
              v-if="item.sub.length < 1"
              :to="item.link" 
              color="transparent"
              :style="clipped ? 'padding: 0px 13px;' : ''">
              <v-list-item-avatar
                size="40"
                tile
                class="mr-4"
                :class="item.link.split('/')[1] === $route.fullPath.split('/')[1] ? '' : 'box_false'"
                :color="item.link.split('/')[1] === $route.fullPath.split('/')[1] ? '#C8EEFF' : '#FFFFFF'"
                :style="item.link.split('/')[1] === $route.fullPath.split('/')[1] ? `border: 2px solid ${set_color};` : ''"
                style="border-radius: 10px !important;">
                <v-icon :color="item.link.split('/')[1] === $route.fullPath.split('/')[1] ? `${set_color}` : ''">{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="body-2"
                  @mouseover="voiceHover(item.title)"
                  :class="item.link.split('/')[1] === $route.fullPath.split('/')[1] ? '' : 'grey--text'"
                  :style="item.link.split('/')[1] === $route.fullPath.split('/')[1] ? `color: ${set_color}; font-weight: 600;` : ''">
                  {{ item.title }} 
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-if="item.title !== 'Setting' && item.sub.length > 0"
              v-model="item.active"
              :color="set_color"
              :append-icon="item.sub.length > 0 ? 'mdi-pan-down' : ''"
              no-action
              :class="clipped ? 'clipped_header' : ''">
              <template v-slot:activator>
                <v-list-item-avatar
                  size="40"
                  tile
                  class="mr-4"
                  :class="item.active || $route.name === item.link ? '' : 'box_false'"
                  :color="item.active || $route.name === item.link ? '#C8EEFF' : '#FFFFFF'"
                  :style="item.active || $route.name === item.link ? `border: 2px solid ${set_color};` : ''"
                  style="border-radius: 10px !important;">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="body-2"
                    @mouseover="voiceHover(item.title)"
                    :class="item.active || $route.name === item.link? 'font-weight-bold' : 'grey--text'"
                    :style="$route.name === item.link ?  `color: ${set_color} !important;`: item.active === $route.fullPath ? `color: ${set_color};` : ''">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <div v-if="item.link === 'profil'" class="ml-4">
                <v-list-item
                  v-for="(item_sub, index_sub) in item.sub"
                  :key="index_sub"
                  :to="`${item_sub.link}`"
                  color="transparent">
                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2"
                      @mouseover="voiceHover(item_sub.title)"
                      :class="item_sub.link === $route.fullPath ? 'font-weight-bold' : 'grey--text'"
                      :style="item_sub.link === $route.fullPath ? `color: ${set_color};` : ''">
                      {{ item_sub.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>

              <div v-if="item.link === 'PPID'" class="ml-4">
                <v-list-item
                  v-for="(item_sub, index_sub) in item.sub"
                  :key="index_sub"
                  target="_blank"
                  :href="item_sub.link"
                  color="transparent">
                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2"
                      @mouseover="voiceHover(item_sub.title)"
                      :class="item_sub.link === $route.fullPath ? 'font-weight-bold' : 'grey--text'"
                      :style="item_sub.link === $route.fullPath ? `color: ${set_color};` : ''">
                      {{ item_sub.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>

              <div v-if="item.link !== 'profil' && item.link !== 'PPID'" class="ml-4">
                <v-list-item 
                  v-for="(item_sub, index_sub) in item.sub"
                  :key="index_sub"
                  @click="item.link !== 'layanan' ? filterSubMenuLink(item_sub.link, item_sub.link) : filterSubMenu(item_sub.link, item.link)"
                  color="transparent">
                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2"
                      @mouseover="voiceHover(item_sub.title)"
                      :class="item_sub.link === $route.fullPath ? 'font-weight-bold' : 'grey--text'"
                      :style="item_sub.link === $route.fullPath ? `color: ${set_color};` : ''">
                      {{ item_sub.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>

            </v-list-group>
          </div>
    </v-list>
    </v-navigation-drawer>
  
    <v-app-bar
      app
      fixed
      class="px-2"
      :class="$route.name === '/' || $route.name === 'beranda' ? 'header' : ''"
      :prominent="false"
      :hide-on-scroll="false"
      scroll-target="#scrolling-techniques-4"
      :style="`background-color: #fff`"
      style="height: 64px; z-index: 3; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
      <v-container class="px-0 pt-1">
        <v-row v-if="selected.search === false">
          <v-col 
            class="d-flex align-center pa-0">
            <div>
              <v-btn
                :color="`${set_color}`"
                elevation="0"
                icon
                min-width="40"
                @click="drawer = !drawer"
                class="text-capitalize px-0"
                style="border-radius: 6px;"
                >
                <v-icon size="20">mdi-menu</v-icon>
              </v-btn>
            </div>

            <v-spacer />

            <div>
              <nuxt-link
                to="/"
                class="d-flex align-center">
                <v-img 
                  contain
                  width="120"
                  height="auto"
                  alt="Kabupaten Klaten" 
                  :src="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? require('@/assets/logo/favicon.png') : require('@/assets/logo/favicon.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>

            <v-spacer />

            <div>
              <v-btn
                  :color="`${set_color}`"
                  elevation="0"
                  icon
                  min-width="40"
                  @click="selected.search = true"
                  class="text-capitalize px-0"
                  style="border-radius: 6px;"
                >
                <v-icon size="20">mdi-magnify</v-icon>
                </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="selected.search === true">
          <v-col 
            class="d-flex align-center pa-0">
            <div>
              <v-btn
                :color="`${set_color}`"
                elevation="0"
                icon
                min-width="40"
                @click="selected.search = false"
                class="text-capitalize px-0"
                style="border-radius: 6px;"
                >
                <v-icon size="20">mdi-close</v-icon>
              </v-btn>
            </div>

            <v-spacer />

            <div class="d-flex" style="width: 100%">
              <v-text-field
                solo
                hide-details
                clearable
                dense
                v-model="search"
                v-on:keyup.enter="changeQuery()"
                @click:clear="search = ''; changeQuery();"
                style="border-radius: 6px 0px 0px 6px !important;"
                :color="`${set_color}`"
                :placeholder="`Cari ${selected.category} yang Anda butuhkan . . .`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
              <v-btn
                  :color="`${set_color}`"
                  elevation="0"
                  dark
                  height="38"
                  @click="changeQuery()"
                  class="text-capitalize"
                  style="border-radius: 0px 6px 6px 0px;"
                >
                <v-icon size="20">mdi-magnify</v-icon>
                </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        clipped: false,
        drawer: false,
        dialog: {
          auth: false,
          otp: false,
          info: false
        },
        category: [
          {
            name: 'Berita',
            link: 'berita'
          },
          {
            name: 'Pengumuman',
            link: 'pengumuman'
          },
          {
            name: 'Potensi',
            link: 'potensi'
          },
          {
            name: 'Layanan',
            link: 'layanan'
          }
        ],
        selected: {
          auth: 'login',
          category: 'berita',
          search: false
        },
        items: [
          {
            action: 'mdi-ticket',
            items: [{ title: 'List Item' }],
            title: 'Attractions',
          },
          {
            action: 'mdi-silverware-fork-knife',
            items: [
              { title: 'Breakfast & brunch' },
              { title: 'New American' },
              { title: 'Sushi' },
            ],
            title: 'Dining',
          },
          {
            action: 'mdi-school',
            items: [{ title: 'List Item' }],
            title: 'Education',
          },
          {
            action: 'mdi-human-male-female-child',
            items: [{ title: 'List Item' }],
            title: 'Family',
          },
          {
            action: 'mdi-bottle-tonic-plus',
            items: [{ title: 'List Item' }],
            title: 'Health',
          },
          {
            action: 'mdi-briefcase',
            items: [{ title: 'List Item' }],
            title: 'Office',
          },
          {
            action: 'mdi-tag',
            items: [{ title: 'List Item' }],
            title: 'Promotions',
          },
        ],
        menu: [
          {
            title: 'Beranda',
            link: '/',
            icon: 'mdi-home',
            sub: []
          },
          {
            title: 'Profil',
            link: 'profil',
            icon: 'mdi-account',
            sub: [
              {
                title: 'Profil Pejabat',
                link: '/profil?menu=pejabat'
              },
              {
                title: 'Visi dan Misi',
                link: '/profil?menu=visi-misi'
              },
              {
                title: 'Sejarah Singkat',
                link: '/profil?menu=sejarah'
              },
              {
                title: 'Lambang',
                link: '/profil?menu=lambang'
              },
              {
                title: 'Struktur dan Organisasi',
                link: '/profil?menu=struktur'
              },
              {
                title: 'Daftar Pejabat',
                link: '/profil?menu=daftar-pejabat'
              },
              {
                title: 'Geografi dan Topologi',
                link: '/profil?menu=geografis'
              },
              {
                title: 'Tugas dan Fungsi Pemerintah',
                link: '/profil?menu=tugas-fungsi'
              },
              {
                title: 'Daftar Website',
                link: '/profil?menu=daftar-website'
              },
            ]
          },
          // {
          //   title: 'PPID',
          //   link: '/PPID'
          // },
          {
            title: 'PPID',
            icon: 'mdi-domain',
            link: 'PPID',
            sub: [
              {
                title: 'Profil PPID',
                link: 'https://ppid.klaten.go.id/welcome/tugas_kewenangan'
              },
              {
                title: 'Informasi Publik Berkala',
                link: 'https://ppid.klaten.go.id/welcome/berkala'
              },
              {
                title: 'Informasi Publik Setiap Saat',
                link: 'https://ppid.klaten.go.id/welcome/setiap_saat'
              },
              {
                title: 'Informasi Publik Serta Merta',
                link: 'https://ppid.klaten.go.id/welcome/serta_merta'
              },
              {
                title: 'Permintaan Informasi',
                link: 'https://ppid.klaten.go.id/welcome/alur'
              },
              {
                title: 'Pengajuan Keberatan',
                link: 'https://ppid.klaten.go.id/welcome/dokumen'
              },
              {
                title: 'Daftar Informasi Dikecualikan',
                link: 'https://ppid.klaten.go.id/welcome/dokumen'
              },
              {
                title: 'Daftar Informasi Publik',
                link: 'https://ppid.klaten.go.id/welcome/dokumen'
              },
            ]
          },
          {
            title: 'Layanan',
            link: 'layanan',
            icon: 'mdi-office-building-cog-outline',
            sub: [
              {
                title: "Semua Kategori",
                link: ""
              }
            ]
          },
          {
            title: 'Informasi',
            link: '/informasi',
            icon: 'mdi-newspaper-variant-outline',
            sub: [
              {
                title: 'Berita',
                link: '/berita'
              },
              {
                title: 'Pengumuman',
                link: '/pengumuman'
              },
              {
                title: 'Potensi',
                link: '/potensi?category=semua-kategori'
              },
              {
                title: 'Video',
                link: '/video'
              },
              {
                title: 'Galeri',
                link: '/galeri'
              },
            ]
          },
          // {
          //   title: 'Berita',
          //   link: 'berita',
          //   icon: 'mdi-newspaper-variant-outline',
          //   sub: []
          //   // sub: [
          //   //   {
          //   //     title: 'Berita Utama',
          //   //     link: '/berita?category=utama'
          //   //   },
          //   //   {
          //   //     title: 'Berita OPD',
          //   //     link: '/berita?category=opd'
          //   //   },
          //   //   {
          //   //     title: 'Berita Wilayah',
          //   //     link: '/berita?category=wilayah'
          //   //   }
          //   // ]
          // },
          // {
          //   title: 'Potensi',
          //   icon: 'mdi-palm-tree',
          //   link: 'potensi',
          //   sub: [
          //     {
          //       title: "Semua Kategori",
          //       link: ""
          //     }
          //   ]
          // },
          {
            title: 'Agenda',
            icon: 'mdi-calendar-month',
            link: '/agenda',
            sub: []
          },
        ]
      }
    },
    components: {
      
    },
    watch: {
      '$route': function() {
        if (this.$route.path.split('/')[1] === 'berita') {
          this.selected.category = this.$route.path.split('/')[1]
        } else if (this.$route.path.split('/')[1] === 'potensi') {
          this.selected.category = this.$route.path.split('/')[1]
        } else if (this.$route.path.split('/')[1] === 'layanan') {
          this.selected.category = this.$route.path.split('/')[1]
        } else if (this.$route.path.split('/')[1] === 'pengumuman') {
          this.selected.category = this.$route.path.split('/')[1]
        }else {
          this.search = ''
          this.selected.category = 'berita'
        }
      },
      // '$route.query.category': function(val) {
      //   for(let i = 0; i < this.category.length; i++){
      //     if (this.category[i].slug === val) {
      //       this.selected.category = this.category[i].slug
      //     } else {
      //       // status = false
      //     }
      //   }
      // },
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      token () {
        return this.$store.state.member.auth.token
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },

      company () {
        return this.$store.state.header.config
      },
      role_admin () {
        if (this.$route.name === null) {
          this.$router.push('/404')
        } else {
          return this.$route.name.includes('admin')
        }
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()
      if(this.$route.query.search !== undefined){
        this.search = this.$route.query.search
      } else {
        this.search = ''
      }
      this.fetchCategoryLayanan()
    },
    beforeMount() {
      if (this.$route.path.split('/')[1] === 'berita') {
        this.selected.category = this.$route.path.split('/')[1]
      } else if (this.$route.path.split('/')[1] === 'potensi') {
        this.selected.category = this.$route.path.split('/')[1]
      } else if (this.$route.path.split('/')[1] === 'layanan') {
        this.selected.category = this.$route.path.split('/')[1]
      } else if (this.$route.path.split('/')[1] === 'pengumuman') {
          this.selected.category = this.$route.path.split('/')[1]
        }else {
        this.search = ''
        this.selected.category = 'berita'
      }
    },
    created() {
      
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
  responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetchCategory () {
        // this.process.load = true

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/tour/tour_category`)
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.menu[4].sub = [
              {
                title: "Semua Kategori",
                link: ""
              }
            ]
            let category = []
            category = res.results.data.map(item => {
                const container = {};

                container.title = item.category_title;
                container.link = `${(item.category_title.split(' ').join('-')).toLowerCase()}`;

                // this.category = container;
                return container
              })

              console.log(category)
            for (let i in category) {
              this.menu[5].sub.push(
                {
                  title: category[i].title,
                  link: category[i].link
                }
              ) 
              
            }
            this.fetchCategoryLayanan()
              // console.log(usersByLikes)
            // this.pagination = res.results.pagination
            // this.fetch(res.results.data[0].id)
          }
        })
      },
      async fetchCategoryLayanan () {
        // this.process.load = true

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/services/services_category`)
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.menu[3].sub = [
              {
                title: "Semua Kategori",
                link: ""
              }
            ]
            let category = []
            category = res.results.data.map(item => {
                const container = {};

                container.title = item.category_title;
                container.link = `${(item.category_title.split(' ').join('-')).toLowerCase()}`;

                // this.category = container;
                return container
              })

              console.log(category)
            for (let i in category) {
              this.menu[3].sub.push(
                {
                  title: category[i].title,
                  link: category[i].link
                }
              ) 
              
            }
              // console.log(usersByLikes)
            // this.pagination = res.results.pagination
            // this.fetch(res.results.data[0].id)
          }
        })
      },
      changeQuery() {
        let category = this.$route.query.category
        if(this.$route.query.search === undefined) {
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': 'semua-kategori', 'search': this.search}})
          } else {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': category, 'search': this.search}})
          }
        } else if (this.$route.query.search !== undefined && this.search !== '') {
          // console.log('cobaaa')
          // router.push({ path: 'page', query: { search: null } })
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': 'semua-kategori', 'search': this.search}})
          } else {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': category, 'search': this.search}})
          }
          // this.$router.replace({'query': {'search': this.search}})
          // router.push({ path: 'page', query: { search: this.search } })
        } else if (this.$route.query.search !== undefined && this.search === '') {
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': `/${this.selected.category}`})
          } else {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': category}})
          }
        }
      },
      toFilterCategory() {
        if(this.$route.query.search === undefined) {
          // this.$router.replace({'path': '/page', 'query': {'category': `/${this.selected.category}`}})
          this.$router.replace({'path': `/${this.selected.category}`})
        } else {
          // this.$router.replace({'path': '/page', 'query': {'category': `/${this.selected.category}`, 'search': this.search}})
          this.$router.replace({'path': `/${this.selected.category}`, 'query': {'search': this.search}})
        }
      },
      filterSubMenu(name, link) {
        // if(name === '') {
        //   if (this.$route.query.search !== undefined) {
        //     this.$router.replace({'path': link, 'query': {'search': this.$route.query.search}})
        //   } else {
        //     this.$router.replace({'path': link})
        //   }
        // } else {
          // console.log('cobaaa')
          // router.push({ path: 'page', query: { search: null } })
          if (this.$route.query.search === undefined) {
            this.$router.replace({'path': `/${link}`, 'query': {'category': name}})
          } else {
            this.$router.replace({'path': `/${link}`, 'query': {'category': name, 'search': this.$route.query.search}})
          }
          // this.$router.replace({'query': {'search': this.$route.query.search}})
          // router.push({ path: 'page', query: { search: this.$route.query.search } })
        // } 
      },
      filterSubMenuLink(name, link) {
        this.$router.push(`${link}`)
      },
      toList(link) {
        this.$router.push(`${link}`)
      },
      goTo (item) {
        if (item.type === 'logout') {
          Cookie.remove('token')
          Cookie.remove('user')
          Cookie.remove('fcm')
          Cookie.remove('notif')
          
          window.location = '/admin/auth/login'
        } else {
          this.$router.push('/admin/profil')
        }
      },
      goBack () {
        if (this.$route.name === 'reservasi-slug') {
          this.$router.push('/reservasi')
        } else if (this.$route.name === 'berita-slug') {
          this.$router.push('/berita')
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>

<style>
  .header {
    /* background-color: transparent !important; */
  }
  .header-default {
    background-color: #a20000 !important;
  }
  .header-detail {
    background-color: transparent !important;
  }
  html:not([data-scroll="0"]) .header {
    /* background: url(@/assets/background/bg_basic.png); */
    /* background-color: #00000040 !important; */
    /* background-color: #a20000 !important; */
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover;
    /* opacity: 80% !important; */
  }
  .header .menu {
    /* color: #FFFFFF !important; */
    background-color: transparent !important;
    /* opacity: 60%; */
  }
  
  .sub .v-list-item {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  box-shadow: none !important;
  border: 1px solid #ebebeb;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  /* border: 0.25px solid #4ab762; */
  border-width: 0.25px !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  /* border: 0.25px solid #4ab762; */
  border-width: 0.25px !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.error_input_border.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  border: 0.25px solid red !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.input-border-right-none.v-input--is-focused > .v-input__control > .v-input__slot {
  border-radius: 6px 0px 0px 6px !important;
}

.border-input-none.v-input--is-focused > .v-input__control > .v-input__slot {
  border-width: none !important;
}

.border-input-none > .v-input__control > .v-input__slot {
  border: none !important;
}

.border-input-none > .v-input__control > .v-input__slot > .v-input__prepend-inner{
  opacity: 0.6;
}

.border-input-none > .v-input__control > .v-input__slot > .v-text-field__slot{
  opacity: 0.6;
}

.input-border-right-none > .v-input__control > .v-input__slot {
  border-radius: 6px 0px 0px 6px !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #FFFFFF !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  margin-bottom: 0px !important;
} 
</style>