<template>
  <div
    style="width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;">
    <!-- <div
      style="background: #00000040;">
      <v-container
        class="py-0">
        <v-row
          align="center">
          <v-col 
            cols="12"
            class="d-flex align-center">
            <div>
              <div
                class="d-flex align-center">
                <v-img 
                  contain
                  width="20"
                  height="20"
                  alt="jdih" 
                  :src="require('@/assets/sosmed/phone.svg')"
                  class="ma-auto"
                  style="opacity: 70%;">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 white--text ml-2"
                  style="opacity: 60%;">
                  085XXXXXXXXX
                </div>

                <div
                  class="mx-4">
                  <v-divider
                    vertical
                    dark
                    style="height: 26px; margin-top: auto;  margin-bottom: auto;" />
                </div>

                <v-img 
                  contain
                  width="20"
                  height="20"
                  alt="jdih" 
                  :src="require('@/assets/sosmed/email.svg')"
                  class="ma-auto"
                  style="opacity: 70%;">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 white--text ml-2"
                  style="opacity: 60%;">
                  XXXX@gmail.com
                </div>
              </div>
            </div>

            <v-spacer />
            
            <v-btn
              v-for="(item, index) in sosmed"
              :key="index"
              color="#00000040"
              width="28"
              min-width="28"
              height="28"
              class="mx-2 pa-0"
              style="border-radius: 10px;">
              <v-img 
                contain
                :width="18"
                :height="18"
                alt="jdih" 
                :src="item.icon"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div> -->

    <div
      style="background: #000000;">
      <v-container class="py-0">
        <v-row
          align="center"
          justify="center">
          <v-col 
            cols="12" class="py-0">

            <v-row>
              <v-col cols="6">
                <div class="mb-3 body-1 font-weight-medium white--text">
                    Pemerintah Kabupaten Klaten
                  </div>
                  <div class="mb-2">
                    <!-- <div class="text-second font-weight-medium fs-15">
                      Email
                    </div> -->
                    <div class="white--text font-weight-medium fs-15">
                      <nuxt-link
                        to="/kebijakan-privasi"
                        class="white--text">
                        Kebijakan Privasi
                      </nuxt-link>
                    </div>
                  </div>
                  <div class="">
                    <div class="text-second font-weight-medium fs-15">
                      Alamat
                    </div>
                    <div class="white--text font-weight-medium fs-15">
                      Jl. Pemuda No. 294 Klaten 57424
                    </div>
                  </div>
                  <div class="">
                    <div class="text-second font-weight-medium fs-15">
                      Telepon
                    </div>
                    <div class="white--text font-weight-medium fs-15">
                      0272-321046
                    </div>
                  </div>
                  <div class="">
                    <div class="text-second font-weight-medium fs-15">
                      Email
                    </div>
                    <div class="white--text font-weight-medium fs-15">
                      pemkab@klaten.go.id
                    </div>
                  </div>
              </v-col>
              <v-col cols="4">
                <div class="body-1 mb-3 white--text"
                  style="opacity: 100%;">
                  Statistik Pengunjung
                </div>
                <div
                  class="align-center justify-center mb-1"
                  v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                  <div>
                    <div
                      class="body-2  white--text mr-4" 
                      style="opacity: 100%;">
                      <table>
                        <tr 
                    v-for="(item, index) in people" 
                    :key="index">
                          <td>{{ item.text }} </td>
                          <td class="pl-2">: <span class="font-weight-bold">{{ item.number }} </span> Pengunjung</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="text-second font-weight-medium white--text mb-3">
                  Sosial Media
                </div>
                <div class="white--text font-weight-medium body-1">
                  <v-avatar 
                    v-for="(item, index) in sosmed"
                    :key="index"
                    tile
                    width="50"
                    height="50">
                    <a
                      :href="item.link"
                      target="blank">
                      <v-img
                        contain
                        width="40"
                        height="40"
                        alt="JDIH"
                        :src="item.icon">
                      </v-img>
                    </a>
                  </v-avatar>
                </div>
              </v-col>
            </v-row>

            <v-divider
              style="border: 1px solid #FFFFFF40; border-radius: 50px;" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"/>

            <!-- <v-row v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
              <v-col cols="12" md="3">
                <div class="text-h5 white--text font-weight-medium mb-6 mt-3">
                  Pelajari
                </div>
                <div>
                  <div
                    class=" mb-4">
                    <div 
                      v-for="(item, index) in site" 
                      :key="index"
                      class="mb-2">
                      <nuxt-link 
                        :to="item.link"
                        class="body-2 font-weight-bold white--text" 
                        style="opacity: 60%;">
                        {{ item.text }}
                      </nuxt-link>

                      <v-divider
                        v-if="index < site.length - 1"
                        vertical
                        style="border: 1px solid #FFFFFF40; border-radius: 50px;" />
                    </div>
                  </div>

                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div class="text-h5 white--text font-weight-medium mb-6 mt-3">
                  Informasi
                </div>
                <div>
                  <div
                    class=" mb-4">
                    <div 
                      v-for="(item, index) in information" 
                      :key="index"
                      class="mb-2">
                      <a 
                        :href="item.link"
                        target="_blank"
                        class="body-2 font-weight-bold white--text" 
                        style="opacity: 60%;">
                        {{ item.text }}
                      </a>

                      <v-divider
                        v-if="index < site.length - 1"
                        vertical
                        style="border: 1px solid #FFFFFF40; border-radius: 50px;" />
                    </div>
                  </div>

                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-h5 white--text font-weight-medium mb-6 mt-3">
                  Layanan
                </div>
                <div>
                  <v-row>
                    <v-col cols="12" md="1"
                      v-for="(item, index) in layanan" 
                      :key="index"
                      class="pa-1">
                      <v-card
                        :to="'/beranda'"
                        class="rounded-xl"
                      >
                        <v-card-title class="justify-center px-1 py-1">
                          <v-avatar size="30">
                            <v-img
                              width="30px"
                              :alt="item.title"
                              :src="item.icon">
                            </v-img>
                          </v-avatar>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>

                </div>
              </v-col>
            </v-row>

            <v-divider
              style="border: 1px solid #FFFFFF40; border-radius: 50px;" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"/> -->
              <v-row>
                <v-col cols="6">
                  <div
                    class="d-flex align-center justify-start mt-0"
                    style="opacity: 60%;">
                    <v-icon small color="white">mdi-copyright</v-icon>
      
                    <span
                      class="body-2 white--text">
                      Copyright Pemerintah Kabupaten Klaten
                    </span>
                  </div>
                </v-col>
                <v-col cols="6" class="text-right">
                  <div
                    class="d-flex align-center justify-end mt-0"
                    style="opacity: 60%;">
                    <!-- <v-icon small color="white">mdi-copyright</v-icon> -->
      
                    <span
                      class="body-2 white--text">
                      Dibuat Oleh Diskominfo Kabupaten Klaten
                    </span>
                  </div>
                </v-col>
              </v-row>

          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        sosmed: [
          { network: 'instagram', link: 'https://www.instagram.com/humaskabklaten/', icon: require('@/assets/sosmed/ic_share_ig.svg') },
          { network: 'twitter', link: 'https://twitter.com/pemkab_klaten', icon: require('@/assets/sosmed/ic_share_tw.svg') }
        ],
        list: {},
        people: [
          {
            text: 'Pengunjung Hari ini ',
            number: '-'
          },
          {
            text: 'Kemarin ',
            number: '-'
          },
          {
            text: 'Bulan ini ',
            number: '-'
          },
          {
            text: 'Tahun ini ',
            number: '-'
          },
          {
            text: 'Total ',
            number: '-'
          }
        ],
        site: [
          {
            text: 'Sejarah Singkat',
            link: '/profil?menu=sejarah'
          },
          {
            text: 'Visi & Misi',
            link: '/profil?menu=visi-misi'
          }
        ],
        information: [
          {
            text: 'Government Public Relations',
            link: 'https://www.kominfo.go.id/'
          },
          {
            text: 'Youtube',
            link: '/beranda'
          },
          {
            text: 'Twitter',
            link: 'https://twitter.com/i/flow/login?redirect_after_login=%2Fpemkab_klaten'
          },
          {
            text: 'Pengumuman',
            link: 'https://klatenkab.go.id/'
          }
        ],
        layanan: [
            {
              title:"SAKURA",
              icon:require('../../../assets/icon/sakura.png'),
              link:""
            },
            {
              title:"Pena Mas",
              icon:require('../../../assets/icon/pena-mas.png'),
              link:""
            },
            {
              title:"Matur Dokter",
              icon:require('../../../assets/icon/matur-dokter.png'),
              link:""
            },
            {
              title:"MPP",
              icon:require('../../../assets/icon/mpp.png'),
              link:""
            },
            {
              title:"Tiket Pariwisata",
              icon:require('../../../assets/icon/tiket.png'),
              link:""
            },
            {
              title:"Pertanian",
              icon:require('../../../assets/icon/pertanian.png'),
              link:""
            }
          ],
      }
    },
    components: {
      
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },

      company () {
        return this.$store.state.header.config
      },
      role_admin () {
        // if (this.$route.name === null) {
        //   this.$router.push('/404')
        // } else {
        //   return this.$route.name.includes('admin')
        // }
      }
    },
    watch: {
      '$route': function() {
        if (this.$route.path === '/beranda' || this.$route.path === '/') {
          this.save()
        }
      },
    },
    created () {
      
    },
    mounted () {
      if (this.$route.path === '/beranda' || this.$route.path === '/') {
        this.save()
        }
        this.fetch()
    },
    methods: {
      async save() {
        // this.message.error = ''

        // const isValid = await this.$refs.form.validate()
        // if (isValid) {
          // this.process.form = true

          let url = 'sites/visitor/add'
          
          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`)
          .then((response) => {

            // this.process.form = false

            if (response.status === 200) {
              // this.dialog.review = false
              // this.dialog.success = true

              this.fetch()
            } else {
              // this.message.error = response.message
            }

          })

        // } else {
        //   // AUTO FOCUS ON TEXT-FIELD
        //   setTimeout(() => {
        //     const errors = Object.entries(this.$refs.form.errors)
        //       .map(([key, value]) => ({ key, value }))
        //       .filter(error => error['value'].length)
        //     this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
        //       behavior: 'smooth',
        //       block: 'center'
        //     })
        //   }, 10)
        // }
      },
      async fetch (id, type) {
        // this.process.load = type === undefined ? true : false

        let params = {
          limit: 1000000
        }

        await this.$axios.$get(`${process.env.API}sites/visitor/data`)
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.people[0].number = res.results.total_hari_ini
            this.people[1].number = res.results.total_kemarin

            this.people[2].number = res.results.total_bulan_ini

            this.people[3].number = res.results.total_tahun_ini

            this.people[4].number = res.results.total_semua
          }
        })
      },
    }
  }
</script>