<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 280px; padding-top: 128px;` : `padding-bottom: 650px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <!-- <v-card
          flat
          cover
          class="background-default">
          <v-img :src="require('@/assets/image/Candi-Prambanan.jpg')" width="100%" height="250" style="opacity: 0.75;" :gradient="`to right, ${set_color_rgb_up}, #000000a6`" class="img_center align-center">
            

            <div class="d-flex align-center justify-center font-weight-black">
              <div class="text-center">
                <div 
                  class="text-h4 white--text justify-center mb-2 pt-6"
                  style="font-weight: 500;">
                  {{ menu[selected.menu].text }}
                </div>
  
                <div 
                  class="body-1 white--text text-center mb-4" 
                  style="opacity: 50%; font-weight: 500;">
                  Mengenal {{ menu[selected.menu].text }} di kabupaten Klaten.
                </div>
              </div>
            </div>

          </v-img>
        </v-card> -->
        <v-card flat style="background: transparent" class="py-0">
          <v-card-text :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'px-0' : ''" class="py-0">
            <section>
              <v-container class="py-0">
    
                <div>
                  <v-row>
                    <!-- <v-col cols="12" md="3" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                      <v-card
                      class="mx-auto"
                      style="border-radius: 15px;"
                      min-height="100%"
                      :class="'elevation-0'"
                      >
                        <v-card-title>
                          <div class="text-h6 font-weight-medium">
                            <span>Kategori</span> Profil
                          </div>
                        </v-card-title>
                        <v-list flat>
                          <v-list-item-group
                            v-model="selected.menu"
                            mandatory
                            :color="set_color"
                          >
                            <v-list-item
                              v-for="(item, i) in menu"
                              :key="i"
                              @click="selected.menu = i; changeQuery(item.link)"
                            >
                              <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                              </v-list-item-icon>
    
                              <v-list-item-content>
                                <div>{{ item.text }}</div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-col> -->
                    <v-col cols="12" :md="selected.menu !== 8 && selected.menu !== 0 ? 9 : 12" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'px-0'">
                      <!-- <div class="mb-8">
                        <v-text-field
                          v-model="search"
                          @click:clear="search = ''; fetch();"
                          v-on:keyup.enter="fetch()"
                          dense
                          rounded
                          solo
                          single-line
                          placeholder="Cari berita yang Anda butuhkan . . ."
                          
                          hide-details
                          clearable
    
                          class="form_white"
                          :color="set_color"
                          append-icon="mdi-magnify">
                        </v-text-field>
                      </div>     
                      <v-row>
                        <v-col 
                          v-for="(item, index) in process.laod ? 6 : list"
                          :key="index"
                          cols="12"
                          md="4">
                          <v-skeleton-loader
                            :loading="process.load"
                            transition="scale-transition"
                            :tile="false"
                            class="ma-auto"
                            type="card">
                            <div>
                              <div v-if="!process.load && list.length > 0"
                                class="border-radius">
                                <v-card
                                  class="mx-auto mt-2 mb-1 button-link-transparent"
                                  style="border-radius: 15px;"
                                  :href="item.link"
                                  target="_blank"
                                  :class="'elevation-0'">
                                  <v-img
                                    cover
                                    height="200"
                                    :src="item.image"
                                  ></v-img>
                      
                                  <v-card-item>
                                    <v-card-title class="text-subtitle-1" :class="'px-4'">{{ item.title }}</v-card-title>
                      
                                    <v-card-subtitle class="pb-1" :class="'px-4'">
                                      
                                      <v-row>
                                        <v-col cols="12" md="5" class="pb-0 pr-0">
                                          <v-icon
                                            size="12">
                                            mdi-account-circle-outline
                                          </v-icon>
                                          <span class="me-1 font-small">{{ item.autor }}</span>
                                        </v-col>
                                        <v-col cols="12" md="7" class="text-right pb-0">
                                          <v-icon
                                            size="12">
                                            mdi-calendar-range
                                          </v-icon>
                                          <span class="me-1 font-small"> {{ item.date}} </span>
                                        </v-col>
                                      </v-row>
                                    </v-card-subtitle>
                                  </v-card-item>
                      
                                  <v-card-text class="pt-2" :class="'px-4'">
                                    <div class="text-caption">
                                      <span style="white-space: pre-line;" v-html="item.description === '' ? '-' : item.description.substring(0, 100) + '...'"> </span>
                                    </div>
                                  </v-card-text>
                      
                                  <v-divider class="mx-4 mb-4"></v-divider>
                      
                                  <div :class="'px-4'" class="pb-4">
                                    <v-chip class="ma-1 ml-0 font-small" v-for="(tag, i) in item.tag" :key="i">{{ tag }}</v-chip>
                                  </div>
                      
                                </v-card>
                              </div>
                            </div>
                          </v-skeleton-loader>
                        </v-col>
                      </v-row>
                      <div v-if="!process.load && pagination.total_data > 20 && limit < pagination.total_data"
                        class="text-center mt-4 mb-2">
                        <v-btn
                          @click="limit += 20"
                          :loading="process.limit"
                          small
                          rounded
                          outlined
                          elevation="0"
                          :color="set_color"
                          class="text-capitalize"
                          style="opacity: 60%;">
                          Lebih banyak
                        </v-btn>
                      </div>
          
                      <Empty v-if="!process.load && pagination.total_page < 1"
                        class="py-12"
                        margin="my-16"
                        size="125"
                        message="Berita" /> -->
                      <Pejabat v-if="selected.menu === 0" />
                      <VisiMisi v-if="selected.menu === 1" />
                      <Sejarah v-if="selected.menu === 2" />
                      <Lambang v-if="selected.menu === 3" />
                      <Struktur v-if="selected.menu === 4" />
                      <Daftar v-if="selected.menu === 5" />
                      <Geografis v-if="selected.menu === 6" />
                      <Tugas v-if="selected.menu === 7" />
                      <Kabupaten v-if="selected.menu === 8" />
                    </v-col>
                    <v-col cols="12" md="3" v-if="selected.menu !== 8 && selected.menu !== 0">
                      <Sidebar />
                    </v-col>
                  </v-row>
                </div>
    
    
              </v-container>
            </section>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  // import VueTree from "@ssthouse/vue-tree-chart";
  export default {
    middleware: false,
    data () {
      return {
        search: '',
        menu: [
          {
            icon: 'mdi-account-circle',
            text: 'Profil Pejabat',
            link: '/profil?menu=pejabat'
          },
          {
            icon: 'mdi-finance',
            text: 'Visi dan Misi',
            link: '/profil?menu=visi-misi'
          },
          {
            icon: 'mdi-city-variant',
            text: 'Sejarah Singkat',
            link: '/profil?menu=sejarah'
          },
          {
            icon: 'mdi-file-cabinet',
            text: 'Lambang',
            link: '/profil?menu=lambang'
          },
          {
            icon: 'mdi-sitemap',
            text: 'Struktur Organisasi',
            link: '/profil?menu=struktur'
          },
          {
            icon: 'mdi-sitemap',
            text: 'Daftar Pejabat',
            link: '/profil?menu=daftar-pejabat'
          },
          {
            icon: 'mdi-map-legend',
            text: 'Geografi dan Topologi',
            link: '/profil?menu=geografi-topologi'
          },
          {
            icon: 'mdi-beaker-question',
            text: 'Tugas dan Fungsi',
            link: '/profil?menu=tugas-fungsi'
          },
          {
            icon: 'mdi-office-building-marker',
            text: 'Daftar Website OPD, Kecamatan dan Desa',
            link: '/profil?menu=daftar-website'
          },
        ],
        selected: {
          menu: 0
        },
        pagination: {},

        limit: 20,

        process: {
          load: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        // content: {
        //   url: '/berita',
        //   title: 'Berita',
        //   description: 'Berita Kabupaten Klaten Disbudporapar',
        //   image: require('@/assets/logo/favicon.png')
        // }
      }
    },
    // head () {
    //   return {
    //     title: this.content.title,
    //     meta: createSEOMeta(this.content)
    //   }
    // },
    components: {
      // VueTree,
      Pejabat: () => import(/* webpackPrefetch: true */ '@/components/profil/pejabat.vue'),
      VisiMisi: () => import(/* webpackPrefetch: true */ '@/components/profil/visi-misi.vue'),
      Sejarah: () => import(/* webpackPrefetch: true */ '@/components/profil/sejarah.vue'),
      Lambang: () => import(/* webpackPrefetch: true */ '@/components/profil/lambang.vue'),
      Geografis: () => import(/* webpackPrefetch: true */ '@/components/profil/geografis.vue'),
      Tugas: () => import(/* webpackPrefetch: true */ '@/components/profil/tugas-fungsi.vue'),
      Kabupaten: () => import(/* webpackPrefetch: true */ '@/components/profil/kabupaten-kecamatan.vue'),
      Struktur: () => import(/* webpackPrefetch: true */ '@/components/profil/struktur.vue'),
      Daftar: () => import(/* webpackPrefetch: true */ '@/components/profil/daftar-pejabat.vue'),
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    // components: {
    //   Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    // },
    watch: {
      '$route.query.menu': function(val) {
        this.changeLink(val)
      }
    },

    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    beforeMount () {
      this.changeLink(this.$route.query.menu)
    },
    mounted () {
      // this.fetch()
    },
    methods: {
      async fetch() {
         this.process.load = true

        await this.$axios.$get(`${process.env.API}profile`)
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            this.detail = response.data

            Cookie.set('user', JSON.stringify(response.data))
          }
        })
      },

      changeLink(query) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        
        if (query !== undefined) {
          if (query === 'pejabat') {
              this.selected = {
                menu: 0,
              }
          } else if (query === 'visi-misi') {
            this.selected = {
              menu: 1
            }
          } else if (query === 'sejarah') {
            this.selected = {
              menu: 2
            }
          } else if (query === 'lambang') {
            this.selected = {
              menu: 3
            }
          } else if (query === 'geografis') {
            this.selected = {
              menu: 6
            }
          } else if (query === 'tugas-fungsi') {
            this.selected = {
              menu: 7
            }
          } else if (query === 'daftar-website') {
            this.selected = {
              menu: 8
            }
          } else if (query === 'struktur') {
            this.selected = {
              menu: 4
            }
          } else if (query === 'daftar-pejabat') {
            this.selected = {
              menu: 5
            }
          } 
        }
        if (this.selected.menu === 0) {
          this.$router.push(`/profil?menu=pejabat`)
        }
      },
      changeQuery(link) {
        this.$router.push(link)
      }
    }
    // mounted () {
    //   // this.fetch()
    // },
    // methods: {
    //   async fetch (type) {
    //     this.process.load = type === undefined ? true : false

    //     // let params = {
    //     //   search: this.search,
    //     //   limit: this.limit,

    //     //   post_type: 'berita',
    //     //   post_status: 'publish'
    //     // }

    //     // await this.$axios.$get(`${process.env.API}post`, { params })
    //     // .then(response => {
    //     //   let res = response

    //     //   this.process.load = false

    //     //   this.process.limit = false
            
    //     //   if (res.code === 200) {
    //     //     this.list = res.data
    //     //     this.pagination = res.pagination
    //     //   }
    //     // })
    //     setTimeout(() => {
    //       this.process.load = false
    //     }, 1000);
    //   }
    // }
  }
</script>