<template>
  <div>
    <v-card
      flat
      min-height="100vh"
      class="d-flex align-center"
      style="z-index: 1;">
      <v-card-text>
        <div
          class="text-h5 text-center font-weight-bold mb-2"
          :style="`color: ${set_color};`">
          <!-- Kabupaten Klaten -->
          Kabupaten Klaten {{ new Date().getFullYear() }}
        </div>

        <!-- <div
          class="body-1 text-center">
          Tiket Pariwisata Disbudporapar
        </div> -->

        <!-- <div
          class="body-1 text-center mt-2">
          Kabupaten Klaten 2023
        </div> -->

        <div
          class="d-flex justify-center ma-6">
          <v-progress-linear
            indeterminate
            height="8"
            rounded
            class="loader"
            :color="set_color">
          </v-progress-linear>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
  export default {
    computed: {
      set_color () {
        return this.$store.state.header.config.color
      }
    }
  }
</script>