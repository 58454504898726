<template>
  <div>

    <!-- <gmap-map
      :center="center"
      :zoom="7"
      style="width: 500px; height: 300px"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map> -->


    <gmap-map
                :center="center"
                :zoom="7"
                style="width: 1000px; height: 500px"
                >
                <gmap-info-window 
            @closeclick="window_open=false" 
            :opened="window_open" 
            :position="selected.position"
        >
            Hello world!
        </gmap-info-window>    
                <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :label="m.label"
                @click="toggleInfo(m, index)"
                ></gmap-marker>
            </gmap-map>
  </div>
  
</template>

<script>

  export default {
    data () {
      return {
        center: {lat: -7.703889,
              lng: 110.601667},
            markers: [
                {
                    label: "A",
                    position: {lat: -7.703889,
              lng: 110.601667}
                }, 
                // {
                //     label: "B",
                //     position: {lat: 11.0, lng: 11.0}
                // }
            ],
        selected: {
          position: {}
        },
      // center: { lat: 22.449769, lng: .3902178 },

      // markers: [
      //   {
      //     position: {
      //       lat: 22.449769,
      //       lng: .3902178
      //     }
      //   }
      // ],
      infowindow: {},
      window_open: false
    };
},

methods: {
  toggleInfo (m) {
    this.selected.position = m.position
            this.window_open = true
        }
    }   

  }
</script>