<template>
  <div
    class="fill-height">
    <!-- style="padding-bottom: 900px; padding-top: 128px;" -->
    <v-layout wrap>
      <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
        Geografi & Topologi
      </div>
      <!-- <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col  
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title 
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    Geografi dan Topologi
                  </v-card-title>

                  <v-card-subtitle 
                    class="body-1 white--text text-center mb-4" 
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal Geografi dan Topologi Kabupaten Klaten.
                  </v-card-subtitle>
                  
                </v-card>
              </v-col>
            </v-row>

            <div>

            </div>
          </v-container>

          <v-container>
            <v-row>
              <v-col>
                <v-card
                  flat
                  min-height="70vh"
                  color="transparent"
                  class="mb-12"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                  <div 
                    id="map_layer" 
                    style="width: 100%; height: 70vh;">
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <div
              class="mb-6 font-weight-bold text-h5">
              Letak Geografis
            </div>

            <div
              class="mb-6">
              <div class="font-weight-medium title">
                Wilayah Kabupaten Klaten terletak antara :
              </div>
              <div class="body-1">
                <ul>
                  <li class="py-1">
                    BujurTimur :1100 26’ 14”  – 1100 47’ 51”
                  </li>
                  <li class="py-1">
                    Lintang Selatan :70 32’ 19”  –    70 48’ 33”
                  </li>
                </ul>
              </div>

            </div>

            <div
              class="mb-6">
              <div class="font-weight-medium title">
                Wilayah Kabupaten Klaten berbatasan dengan Kabupaten :
              </div>
              <div class="body-1">
                <ul>
                  <li class="py-1">
                    Sebelah Utara :Kabupaten Boyolali
                  </li>
                  <li class="py-1">
                    SebelahTimur :Kabupaten Sukoharjo
                  </li>
                  <li class="py-1">
                    Sebelah Selatan :Kabupaten Gunungkidul (DIY)
                  </li>
                  <li class="py-1">
                    Sebelah Barat :Kabupaten Sleman (DIY)
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="mb-6">
              <div class="font-weight-medium title">
                Wilayah Kabupaten Klaten terbagi menjadi tiga dataran :
              </div>
              <div class="body-1">
                <ul>
                  <li class="py-1">
                    Sebelah Utara :Dataran Lereng Gunung Merapi
                  </li>
                  <li class="py-1">
                    Sebelah Timur :Membujur Dataran Rendah
                  </li>
                  <li class="py-1">
                    Sebelah Selatan :Dataran Gunung Kapur
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="mb-6">
              <div class="font-weight-medium title">
                Jarak Kota Klaten Dengan Kota Lain Se Karisidenan Surakarta :
              </div>
              <div class="body-1">
                <ul>
                  <li class="py-1">
                    Kabupaten Klaten ke Kabupaten Boyolali : 38 Km
                  </li>
                  <li class="py-1">
                    Kabupaten Klaten ke Kabupaten Wonogiri : 67 Km
                  </li>
                  <li class="py-1">
                    Kabupaten Klaten ke Kota Solo : 36 Km
                  </li>
                  <li class="py-1">
                    Kabupaten Klaten ke Kabupaten Karanganyar : 49 Km
                  </li>
                  <li class="py-1">
                    Kabupaten Klaten ke Kabupaten Sukoharjo : 47 Km
                  </li>
                  <li class="py-1">
                    Kabupaten Klaten ke  Kabupaten Sragen : 63 Km
                  </li>
                </ul>
              </div>
            </div>
          </v-container>
        </section>
      </v-flex>

      <v-container>
        <section>
          <div
            class="mb-6 font-weight-bold text-h5">
            Topografi
          </div>

          <div style="text-indent: 50px;">
            Topografi merupakan suatu pembahasan mengenai posisi suatu bagian dan secara umun menunjuk pada koordinat secara horizontal seperti garis lintang dan garis bujur, dan secara vertikal yaitu ketinggian.Topografi Kabupaten Klaten terletak di antara Gunung Merapi dan Pegunungan Seribu dengan ketinggian antara 75 hingga 160 meter Di Atas Permukaan Laut yang terbagi menjadi wilayah Lereng Gunung Merapi di bagian utara areal miring, wilayah datar dan berbukit di bagian selatan.
          </div>

          <div
            class="mb-6">
            Jika ditinjau dari ketinggiannya, Kabupaten Klaten terdiri dari dataran dan pegunungan yang berada pada ketinggian bervariasi, yaitu 9,72 persen terletak di ketinggian 0–100 meter dari permukaan air laut. 77,52 persen terletak di ketinggian 100–500 meter dari permukaan air laut dan 12,76 persen terletak di ketinggian 500–1.000 meter dari permukaan air laut.
          </div>

          <div
            class="mb-6">
            Keadaan iklim Kabupaten Klaten termasuk iklim tropis dengan musim hujan dan kemarau silih berganti sepanjang tahun, temperatur udara rata-rata 28–30 derajat Celsius dengan kecepatan angin rata-rata sekitar 153 milimeter setiap bulannya dengan curah hujan tertinggi pada Bulan Januari (350 mm) dan curah hujan terrendah pada Bulan Juli (8 mm).
          </div>

          <div
            class="mb-6">
            Sebagian besar wilayah kabupaten ini adalah dataran rendah dan tanah bergelombang. Bagian barat laut merupakan pegunungan, bagian dari sistem Gunung Merapi. Ibukota kabupaten ini berada di jalur utama Solo-Yogyakarta.
          </div>

        </section>
      </v-container>

      <v-container>
        <section>
          <div
            class="mb-6 font-weight-bold text-h5">
            Keadaan Wilayah
          </div>

          <div
            class="mb-6">
            <div class="font-weight-medium title">
              Wilayah Kabupaten Klaten terbagi menjadi 3 (tiga)  dataran :
            </div>
            <div class="body-1">
              <ol>
                <li class="py-1">
                  Wilayah Lereng Gunung Merapi (wilayah bagian utara) membentang di sebelah utara meliputi sebagian kecil sebelah utara wilayah Kecamatan Kemalang, Karangnongko, Jatinom dan Tulung.
                </li>
                <li class="py-1">
                  Wilayah Dataran (wilayah bagian tengah) yang meliputi wilayah kecamatan : Manisrenggo, Klaten Tengah, Klaten Utara, Klaten Selatan, Kalikotes, Ngawen, Kebonarum, Wedi, Jogonalan, Prambanan, Gantiwarno, Delanggu, Wonosari, Juwiring, Ceper, Pedan, Karangdowo, Trucuk, Cawas, Karanganom dan Polanharjo.
                </li>
                <li class="py-1">
                  Wilayah Berbukit/Gunung Kapur (wilayah bagian selatan) yang membujur di sebelah selatan meliputi sebagian kecil sebelah selatan Kecamatan Bayat,Cawas dan sebagian Gantiwarno.
                </li>
              </ol>
            </div>
          </div>

          <div
            class="mb-6">
            Melihat keadaan alamnya yang sebagian besar adalah dataran rendah dan didukung dengan banyaknya sumber air, maka daerah Kabupaten Klaten merupakan daerah pertanian yang potensial disamping penghasil kapur, batu kali dan pasir yang berasal dari Gunung Merapi
          </div>

          <div
            class="mb-6">
            <div class="font-weight-medium title">
              Ketinggian Daerah :
            </div>
            <div class="body-1">
              <ol>
                <li class="py-1">
                  Sekitar  3,72% terletak diantara ketinggian 0 – 100 meter di atas permukaan laut.
                </li>
                <li class="py-1">
                  Terbanyak 83,52% terletak diantara ketinggian 100 – 500 meter diatas permukaan laut.
                </li>
                <li class="py-1">
                  Sisanya 12,76% terletak diantara ketinggian 500 – 2.500 meter diatas permukaan laut.
                </li>
              </ol>
            </div>
          </div>

        </section>
      </v-container>

      <v-container>
        <section>
          <div
            class="mb-6 font-weight-bold text-h5">
            Klasifikasi Tanah
          </div>

          <div
            class="mb-6">
            <div class="font-weight-medium title">
              Jenis tanah terdiri dari 5 (lima) macam :
            </div>
            <div class="body-1">
              <ol>
                <li class="py-1">
                  <div>
                    Litosol
                  </div>
                  <div>
                    Bahan induk dari skis kristalin dan batu tulis  terdapat di daerah Kecamatan Bayat.
                  </div>
                </li>
                <li class="py-1">
                  <div>
                    Regosol Kelabu
                  </div>
                  <div>
                    Bahan induk abu dan pasir vulkanik termedier terdapat di Kecamatan Cawas, Trucuk, Klaten Tengah, Kalikotes, Kebonarum, Klaten Selatan, Karangnongko, Ngawen, Klaten Utara, Ceper, Pedan, Karangdowo, Juwiring, Wonosari, Delanggu, Polanharjo, Karanganom, Tulung dan Jatinom.
                  </div>
                </li>
                <li class="py-1">
                  <div>
                    Grumusol Kelabu Tua
                  </div>
                  <div>
                    Bahan induk berupa abu dan pasir vulkanik termedier terdapat didaerah Kecamatan Bayat, Cawas sebelah selatan.
                  </div>
                </li>
                <li class="py-1">
                  <div>
                    Kompleks Regosol Kelabudan Kelabu Tua
                  </div>
                  <div>
                    Bahan induk berupa batu Kapur napal terdapat di daerah Kecamatan Klaten Tengah dan Kalikotes sebelah selatan.
                  </div>
                </li>
                <li class="py-1">
                  <div>
                    Regosol Coklat Ke kelabuan
                  </div>
                  <div>
                    Bahan induk berupa abu dan pasir vulkanik termedier terdapat di daerah Kecamatan Kemalang, Manisrenggo,  Prambanan, Jogonalan, Gantiwarno dan Wedi.
                  </div>
                </li>
              </ol>
            </div>
          </div>

        </section>
      </v-container> -->
      <v-card
        flat
        :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'pa-5' : 'px-0'">
        <v-row>
          <v-col :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
            <v-card
              flat
              height="50vh"
              color="transparent"
              class="mb-12"
              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
              <div 
                id="map_layer" 
                style="width: 100%; height: 50vh;">
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div>
          <section>
            <div
              class="mb-6 font-weight-bold text-h5">
              Topografi
            </div>

            <div style="text-indent: 50px;">
              Topografi merupakan suatu pembahasan mengenai posisi suatu bagian dan secara umun menunjuk pada koordinat secara horizontal seperti garis lintang dan garis bujur, dan secara vertikal yaitu ketinggian.Topografi Kabupaten Klaten terletak di antara Gunung Merapi dan Pegunungan Seribu dengan ketinggian antara 75 hingga 160 meter Di Atas Permukaan Laut yang terbagi menjadi wilayah Lereng Gunung Merapi di bagian utara areal miring, wilayah datar dan berbukit di bagian selatan.
            </div>

            <div
              class="mb-6">
              Jika ditinjau dari ketinggiannya, Kabupaten Klaten terdiri dari dataran dan pegunungan yang berada pada ketinggian bervariasi, yaitu 9,72 persen terletak di ketinggian 0–100 meter dari permukaan air laut. 77,52 persen terletak di ketinggian 100–500 meter dari permukaan air laut dan 12,76 persen terletak di ketinggian 500–1.000 meter dari permukaan air laut.
            </div>

            <div
              class="mb-6">
              Keadaan iklim Kabupaten Klaten termasuk iklim tropis dengan musim hujan dan kemarau silih berganti sepanjang tahun, temperatur udara rata-rata 28–30 derajat Celsius dengan kecepatan angin rata-rata sekitar 153 milimeter setiap bulannya dengan curah hujan tertinggi pada Bulan Januari (350 mm) dan curah hujan terrendah pada Bulan Juli (8 mm).
            </div>

            <div
              class="mb-6">
              Sebagian besar wilayah kabupaten ini adalah dataran rendah dan tanah bergelombang. Bagian barat laut merupakan pegunungan, bagian dari sistem Gunung Merapi. Ibukota kabupaten ini berada di jalur utama Solo-Yogyakarta.
            </div>

          </section>
        </div>

        <div>
          <section>
            <div
              class="mb-6 font-weight-bold text-h5">
              Keadaan Wilayah
            </div>

            <div
              class="mb-6">
              <div class="font-weight-medium">
                Wilayah Kabupaten Klaten terbagi menjadi 3 (tiga)  dataran :
              </div>
              <div class="">
                <ol>
                  <li class="py-1">
                    Wilayah Lereng Gunung Merapi (wilayah bagian utara) membentang di sebelah utara meliputi sebagian kecil sebelah utara wilayah Kecamatan Kemalang, Karangnongko, Jatinom dan Tulung.
                  </li>
                  <li class="py-1">
                    Wilayah Dataran (wilayah bagian tengah) yang meliputi wilayah kecamatan : Manisrenggo, Klaten Tengah, Klaten Utara, Klaten Selatan, Kalikotes, Ngawen, Kebonarum, Wedi, Jogonalan, Prambanan, Gantiwarno, Delanggu, Wonosari, Juwiring, Ceper, Pedan, Karangdowo, Trucuk, Cawas, Karanganom dan Polanharjo.
                  </li>
                  <li class="py-1">
                    Wilayah Berbukit/Gunung Kapur (wilayah bagian selatan) yang membujur di sebelah selatan meliputi sebagian kecil sebelah selatan Kecamatan Bayat,Cawas dan sebagian Gantiwarno.
                  </li>
                </ol>
              </div>
            </div>

            <div
              class="mb-6">
              Melihat keadaan alamnya yang sebagian besar adalah dataran rendah dan didukung dengan banyaknya sumber air, maka daerah Kabupaten Klaten merupakan daerah pertanian yang potensial disamping penghasil kapur, batu kali dan pasir yang berasal dari Gunung Merapi
            </div>

            <div
              class="mb-6">
              <div class="font-weight-medium">
                Ketinggian Daerah :
              </div>
              <div class="">
                <ol>
                  <li class="py-1">
                    Sekitar  3,72% terletak diantara ketinggian 0 – 100 meter di atas permukaan laut.
                  </li>
                  <li class="py-1">
                    Terbanyak 83,52% terletak diantara ketinggian 100 – 500 meter diatas permukaan laut.
                  </li>
                  <li class="py-1">
                    Sisanya 12,76% terletak diantara ketinggian 500 – 2.500 meter diatas permukaan laut.
                  </li>
                </ol>
              </div>
            </div>

          </section>
        </div>

        <div>
          <section>
            <div
              class="mb-6 font-weight-bold text-h5">
              Klasifikasi Tanah
            </div>

            <div
              class="mb-6">
              <div class="font-weight-medium">
                Jenis tanah terdiri dari 5 (lima) macam :
              </div>
              <div class="">
                <ol>
                  <li class="py-1">
                    <div>
                      Litosol
                    </div>
                    <div>
                      Bahan induk dari skis kristalin dan batu tulis  terdapat di daerah Kecamatan Bayat.
                    </div>
                  </li>
                  <li class="py-1">
                    <div>
                      Regosol Kelabu
                    </div>
                    <div>
                      Bahan induk abu dan pasir vulkanik termedier terdapat di Kecamatan Cawas, Trucuk, Klaten Tengah, Kalikotes, Kebonarum, Klaten Selatan, Karangnongko, Ngawen, Klaten Utara, Ceper, Pedan, Karangdowo, Juwiring, Wonosari, Delanggu, Polanharjo, Karanganom, Tulung dan Jatinom.
                    </div>
                  </li>
                  <li class="py-1">
                    <div>
                      Grumusol Kelabu Tua
                    </div>
                    <div>
                      Bahan induk berupa abu dan pasir vulkanik termedier terdapat didaerah Kecamatan Bayat, Cawas sebelah selatan.
                    </div>
                  </li>
                  <li class="py-1">
                    <div>
                      Kompleks Regosol Kelabudan Kelabu Tua
                    </div>
                    <div>
                      Bahan induk berupa batu Kapur napal terdapat di daerah Kecamatan Klaten Tengah dan Kalikotes sebelah selatan.
                    </div>
                  </li>
                  <li class="py-1">
                    <div>
                      Regosol Coklat Ke kelabuan
                    </div>
                    <div>
                      Bahan induk berupa abu dan pasir vulkanik termedier terdapat di daerah Kecamatan Kemalang, Manisrenggo,  Prambanan, Jogonalan, Gantiwarno dan Wedi.
                    </div>
                  </li>
                </ol>
              </div>
            </div>

          </section>
        </div>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        map: null,

        point: null,
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=geografis',
          title: 'Geografi & Topologi',
          description: 'Geografi & Topologi Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.initMap()
    },
    methods: {
      initMap () {
        let self = this

        this.$gmapApiPromiseLazy().then(() => {
          self.map = new google.maps.Map(document.getElementById('map_layer'), {
            center: { 
              lat: -7.673,
              lng: 110.619
            },
            zoom: 11.5,
            noClear: true,
            mapTypeId: 'hybrid', //satellite
            navigationControl: true,
            mapTypeControl: true,
            streetViewControl: true,
            tilt: 0
          })

          let getTileUrl = function(coordinates, zoom) {
            return (
              'https://sitaru.klaten.go.id/geoserver/wms?' +
              '&REQUEST=GetMap&SERVICE=WMS&VERSION=1.1.1' +
              '&LAYERS=rencana_pola_ruang_view' +
              '&FORMAT=image%2Fpng' +
              '&SRS=EPSG:3857&WIDTH=256&HEIGHT=256' +
              '&TRANSPARENT=true' +
              '&BBOX=' + self.xyzToBounds(coordinates.x, coordinates.y, zoom).join(',')
            )
          }

          let landcover = new google.maps.ImageMapType({
            getTileUrl: getTileUrl,
            name: 'Landcover',
            alt: 'National Land Cover Database 2023',
            tileSize: new window.google.maps.Size(256, 256),
            isPng: true,
            minZoom: 0,
            maxZoom: 24,
            opacity: 0.50
          })
          
          // self.map.overlayMapTypes.push(landcover)

          self.map.overlayMapTypes.setAt('0', landcover)

          google.maps.event.addListener(self.map, 'click', function (event) {  
            console.log(event)
          })
        })
      },

      xyzToBounds (x, y, z) {
        let EXTENT = [-Math.PI * 6378137, Math.PI * 6378137]

        let tileSize = (EXTENT[1] * 2) / Math.pow(2, z)
        let minx = EXTENT[0] + x * tileSize
        let maxx = EXTENT[0] + (x + 1) * tileSize

        // remember y origin starts at top
        let miny = EXTENT[1] - (y + 1) * tileSize
        let maxy = EXTENT[1] - y * tileSize

        return [minx, miny, maxx, maxy]
      }
    }
  }
</script>