<template>
  <div
    class="fill-height">
    <!-- style="padding-bottom: 900px; padding-top: 128px;" -->
    <v-layout wrap>
      <v-flex>
        <section>
          <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
            Sejarah Singkat
          </div>
          <!-- <v-container>
            <v-row>
              <v-col  
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title 
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    Sejarah
                  </v-card-title>

                  <v-card-subtitle 
                    class="body-1 white--text text-center mb-4" 
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal Sejarah Kabupaten Klaten.
                  </v-card-subtitle>
                  
                </v-card>
              </v-col>
            </v-row>

          </v-container> -->
          <v-row>
            <v-col  
              cols="12" class="py-0" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
              <v-card
                flat>

                <v-card-text
                  class="body-1 line-text-second mb-4" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'px-0'">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-img
                        :src="require('../../assets/image/profile/Makam-Kiyai-Mlati.jpeg')"
                        :lazy-src="require('../../assets/image/profile/Makam-Kiyai-Mlati.jpeg')"
                        alt="Kabupaten Klaten"
                        class="rounded-lg">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <!-- <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126517.86062676377!2d110.6537602!3d-7.7171062500000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a416389cafa5d%3A0x3027a76e352bad0!2sKlaten%2C%20Klaten%20Regency%2C%20Central%20Java!5e0!3m2!1sen!2sid!4v1694135891167!5m2!1sen!2sid" width="100%" height="auto" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div> -->
                    </v-col>
                    <v-col cols="12" md="8">
                      <div style="text-indent: 50px;">
                        Sejarah Klaten tersebar diberbagai catatan arsip-arsip kuno dan kolonial, arsip-arsip kuno dan manuskrip Jawa. Catatan itu seperti tertulis dalam Serat Perjanjian Dalem Nata, Serat Ebuk Anyar, Serat Siti Dusun, Sekar Nawala Pradata, Serat Angger Gunung, Serat Angger Sedasa dan Serat Angger Gladag. 
                      </div>
                    </v-col>
                  </v-row>
                  <div>
                    Dalam bundel arsip Karesidenan Surakarta menjadikan rujukan sejarah Klaten seperti tercantum dalam Soerakarta Brieven van Buiten Posten, Brieven van den Soesoehoenan 1784-1810, Daghregister van den Resi dentie Soerakarta 1819, Reporten 1787-1816, Rijksblad Soerakarta dan Staatblad van Nederlandsche Indie. Babad Giyanti, Babad Bedhahipun Karaton Negari Ing Ngayogyakarta, Babad Tanah Jawi dan Babad Sindula menjadi sumber lain untuk menelusuri sejarah Klaten.
                  </div>
                  <div
                    class="mb-6">
                    <div class="d-flex align-center">
                      <div class="mr-5">
                        <v-icon size="100">mdi-format-quote-open</v-icon>
                      </div>
                      <div class="font-italic font-weight-medium">
                        Cerita Kyai dan Nyai Mlati dianggap sebagai sumber terpercaya yang diakui sebagai cikal bakal kampung dan asal muasal nama Klaten yang konon tinggal di kampung Sekalekan
                      </div>
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    Baik sumber arsip kolonial, arsip kuno maupun manuskrip Jawa ternyata saling memperkuat dan melengkapi dalam menelusuri sejarah Klaten. Cerita Kyai dan Nyai Mlati dianggap sebagai sumber terpercaya yang diakui sebagai cikal bakal kampung dan asal muasal nama Klaten yang konon tinggal di kampung Sekalekan. Kedua abdi dalem Kraton Mataram ini ditugaskan oleh raja untuk menyerahkan bunga Melati dan buah Joho untuk menghitamkan gigi para putri kraton (Serat Narpawada, 1919:1921).
                  </div>

                  <div
                    class="mb-6">
                    Guna memenuhi kebutuhan bunga Melati untuk raja, Kyai dan Nyai Mlati menanami sawah milik Raden Ayu Mangunkusuma, istri Raden Tumenggung Mangunkusuma yang saat itu menjabat sebagai Bupati Polisi Klaten, yang kemudian dipindah tugaskan istana menjadi Wakil Patih Pringgalaya di Surakarta. Tidak ditemukan sumber sejarah tentang akhir riwayat Kyai dan Nyai Melati. Silsilah Kyai dan Nyai Melati juga tidak diketahui. Bahkan penduduk Klaten tidak ada yang mengakui sebagai keturunan dua sosok penting ini.
                  </div>

                  <div
                    class="mb-6">
                    Sejarah Klaten juga dapat ditelusuri dari keberadaan Candi-candi Hindu, Budha maupun barang-barang kuno. Asal muasal desa-desa kuno tempo dulu menunjukan keterangan terpercaya. Desa-desa seperti Pulowatu, Gumulan, Wedihati, Mirah-mirah maupun Upit. Peninggalan atau petilasan Ngupit bahkan secara jelas menyebutkan pertanda tanggal yang dimaknai 8 November 66 Maeshi oleh Raden Rakai Kayuwangi.
                  </div>

                  <div
                    class="mb-6">
                    Berdirinya Benteng atau loji Klaten di masa pemerintahan Sunan Paku Buwana IV mempunyai arti penting dalam sejarah Klaten.
                  </div>

                  <div
                    class="mb-6">
                    Pendirian benteng tersebut peletakan batu pertamanya dimulai pada hari sabtu Kliwon, 12 rabiulakir, Langkir, Alit 1731 atau sengkala RUPA MANTRI SWARANING JALAK atau dimaknai sebagai tanggal 28 Juli 1804. Sumber sejarah ini dapat ditemukan dalam Babad Bedhaning Ngayogyakarata dan Geger Sepehi. Catatan sejarah ini oleh pemerintah Kabupaten Klaten melalui Peraturan Daerah Nomor 12 Tahun 2007 sebagai Hari Jadi Kabupaten Klaten yang diperingati setiap tahun.
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=sejarah',
          title: 'Sejarah Singkat',
          description: 'Sejarah Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>