<template>
  <div
    class="fill-height">
    <!-- style="padding-bottom: 900px; padding-top: 128px;" -->
    <v-layout wrap>
      <v-flex>
        <section>
          <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
            Lambang
          </div>
          <!-- <v-container>
            <v-row>
              <v-col  
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title 
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    Lambang
                  </v-card-title>

                  <v-card-subtitle 
                    class="body-1 white--text text-center mb-4" 
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal Lambang Kabupaten Klaten.
                  </v-card-subtitle>
                  
                </v-card>
              </v-col>
            </v-row>

          </v-container> -->
          <v-row>
            <v-col  
              cols="12" class="py-0" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : ''">
              <v-card
                flat>

                <v-card-text
                  class="body-1 line-text-second mb-4" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'px-0'">
                  
                  <v-img
                    :src="require('@/assets/image/profile/lambang.png')"
                    :lazy-src="require('@/assets/image/profile/lambang.png')"
                    alt="Kabupaten Klaten"
                    aspect-ratio="2.5"
                    contain
                    class="rounded-lg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <div
                    class="mb-6 font-weight-bold text-h5 mt-10">
                    ARTI LAMBANG KABUPATEN KLATEN
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      MAHKOTA KERAJAAN
                    </div>
                    <div class="body-1">
                      Melambangkan kebesaran/keagungan  cita-cita.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      ORANG YANG BERSEMEDI DENGAN RAMBUT TERURAI, KAKI BERBENTUK PITA BERTULISKAN “KLATEN”
                    </div>
                    <div class="body-1">
                      Orang  dengan tekat  yang teguh dan suci menuju cita-cita Kab Klaten.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      PERISAI WARNA KUNING EMAS DENGAN BINGKAI SEGI LIMA WARNA PUTIH
                    </div>
                    <div class="body-1">
                      Menggambarkan perlindungan rakyat menuju jaman keemasan “Toto Tentrem Kerto Raharjo” dengan prinsip kebajikan, kejujuran, keadilan dan kebenaran.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      PADI DAN KAPAS
                    </div>
                    <div class="body-1">
                      Mencerminkan sandang dan pangan dari hasil pertanian dan perkebunan padi warna kuning emas jumlah 28 kapas warna putih jumlah 10.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      TULISAN MENYERUPAI HURUF JAWA
                    </div>
                    <div class="body-1">
                      “Tumengo Toto Anggotro Raharjo “  artinya 0591  (1950) Hari jadi Pemerintah Kab Klaten  (ditanah jawa) 28-10-1950
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      GUNUNGAN WARNA BIRU MUDA
                    </div>
                    <div class="body-1">
                      Gunungan = pembukaan
                    </div>
                    <div class="body-1">
                      Warna biru muda = warna cerah, tenang dan tenteram
                    </div>
                    <div class="body-1">
                      yaitu tanda pembukaan berdirinya Pemerintah Kab Klaten dan membuka keadaan baru yang tenang dan tenteram.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      RANTAI WARNA KUNING MELINGKAR DIBATANG PADA DAN KAPAS
                    </div>
                    <div class="body-1">
                      Menggambarkan persatuan dan kegotong royongan rakyat.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      BAMBU RUNCING DAN API
                    </div>
                    <div class="body-1">
                      Merupakan simbul perjuangan yang berkobar dan menyala  menuju cita-cita  yang suci dan mulia.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      TUGU WARNA PUTIH
                    </div>
                    <div class="body-1">
                      Diartikan sebagai peringatan dari perjuangan yang suci.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      POHON BERINGIN BESERTA AKARNYA BERWARNA HIJAU
                    </div>
                    <div class="body-1">
                      Simbul pengayoman dan perlindungan rakyat menuju keadaan sosial ekonomi yang merata, adil dan makmur.
                    </div>
                  </div>

                  <div
                    class="mb-6">
                    <div class="font-weight-medium title">
                      WARNA HITAM PADA DASAR LAMBANG
                    </div>
                    <div class="body-1">
                      Hitam = kuat, tegas, kekal
                      <div>
                        Maksudnya lambang ini bersifat kuat, tegas dan kekal, baik isi maupun tujuannya.
                      </div>
                    </div>
                  </div>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=lambang',
          title: 'Lambang',
          description: 'Lambang Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>