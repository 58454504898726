<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 600px; padding-top: 170px;` : `padding-bottom: 900px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="9">
                <div class="mb-4 d-flex align-center text-second" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'body-2'">
                  <v-btn text to="/pengumuman" class="text-capitalize px-1" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'body-2'" color="blue">
                    Pengumuman
                  </v-btn>
                  <div class="mr-3 ml-1">
                    .|.
                  </div>
                  <div>
                    Detail Pengumuman
                  </div>
                </div>
                <div v-if="process.load">
                  <v-card
                    flat
                    height="100%"
                    v-if="process.load"
                    :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '100vh'"
                    :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '100vh'"
                    color="#00000040">
                    <v-skeleton-loader
                      v-if="process.load"
                      :loading="process.load"
                      transition="scale-transition"
                      :tile="false"
                      class="ma-auto load_card_x_large"
                      type="image"
                      style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
                    </v-skeleton-loader>
                  </v-card>
                </div>
                <v-card flat class="pa-5" v-if="!process.load && Object.keys(detail).length > 0">
                  <v-card-title class="text-h5 px-0 mb-2">
                    {{ detail.title }}
                  </v-card-title>
                  <div v-if="detail.image_type === 'single' && detail.image_url.length > 0">
                    <v-img contain :src="detail.image_url === '' ? require('@/assets/image/no-photo.png') : detail.image_url" style="border-radius: 6px;"></v-img>
                  </div>
                  <div v-if="detail.image_type === 'multiple'">
                    <!-- <v-img contain :src="detail.image_url === '' ? require('@/assets/image/no-photo.png') : detail.image_url"></v-img> -->
                    <v-carousel height="500" width="100%">
                      <v-carousel-item
                        v-for="(item,i) in detail.image_url_json"
                        :key="i"
                        :src="item.src"
                        contain
                        style="border-radius: 6px;"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                  <v-card-text class="px-0 pb-0">
                    <div>
                      <v-icon
                        size="18">
                        mdi-account-circle-outline
                      </v-icon>
                      <span class="me-1 body-2">Admin</span>
                      <span class="ml-2">
                        <v-icon
                          size="18">
                          mdi-calendar-range
                        </v-icon>
                        <span class="me-1 body-2"> {{ detail.publish_datetime | date }} </span>
                      </span>
                      <span class="ml-2">
                        <v-icon
                          size="18">
                          mdi-eye
                        </v-icon>
                        <span class="me-1 body-2">Dilihat {{ detail.view_count }} Kali </span>
                      </span>
                    </div>
                    <div class="mt-3 font-weight-regular" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'body-2'" style="font-size: 18px;" v-html="detail.content">
                    </div>
                    <div v-if="detail.file_url !== ''">
                      <v-btn
                          style="border-radius: 6px;"
                          elevation="0"
                          :loading="process.form"
                          :disabled="process.form"
                          v-if="detail.file_url !== ''"
                          :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                          color="green"
                          @click="downloadFile(detail.file_url, $route.params.slug)"
                          class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-3">
                          
                          <v-icon size="20" class="mr-2">mdi-download</v-icon>
                          Download
                        </v-btn>
                      <!-- </a> -->
                      <div class="mt-4">
                        <iframe :src="detail.file_url" width="100%" :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '900px' : '500px'" frameborder="0"></iframe>
                      </div>
                    </div>
                  </v-card-text>
                  <v-divider class="my-5"></v-divider>
                  <v-avatar 
                    v-for="(item, index) in sosmed"
                    :key="index"
                    tile
                    width="50"
                    height="50">
                    <ShareNetwork
                      :network="item.network"
                      :url="`${host}${$route.fullPath}`"
                      :title="detail.title"
                      :image="detail.image_url"
                      :description="detail.meta_json.image"
                      hashtags="Klaten,diskominfo">
                      <v-img
                        contain
                        width="40"
                        height="40"
                        alt="Klaten"
                        :src="item.icon">
                      </v-img>
                    </ShareNetwork>
                  </v-avatar>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <!-- <Sidebar /> -->
                <section>
                  <Sidebar />
                </section>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dateNow: new Date(),
        detail: {},
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
          { network: 'instagram', icon: require('@/assets/sosmed/ic_share_ig.svg') },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
        ],
        category: [],
        process: {
          load: false,
          category: false
        },
        total_category: 0,
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/berita/:slug',
          title: this.$route.params.slug.split('-').join(' ').split('=').join('-').split('>').join('/'),
          description: 'Detail Berita Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },
      host () {
        return process.env.HOST_NAME
      }
    },
    mounted () {
      this.fetch()
      this.fetchCategory()
    },
    methods: {
      async fetchCategory (id, type) {
        this.process.category = type === undefined ? true : false

        let params = {
          limit: 1000000,
          par_id: '1'
        }

        await this.$axios.$get(`${process.env.API}sites/article/category`, { params })
        .then(response => {
          let res = response
            
          if (res.status === 200) {
            this.category = [
              {
                category_title: "Semua Kategori",
                category_icon_url: require('@/assets/icon/app.png'),
                id: '',
                category_name: "semua-kategori",
                count: 0
              }
            ]
            let category = []
            category = res.results.data.map(item => {
                const container = {};

                container.category_title = item.title;
                container.category_icon_url = item.icon_url;
                container.id = item.id;
                container.category_name = item.slug;
                container.count = item.count

                // this.category = container;
                return container
              })

              
            for (let i in category) {
              this.category.push(
                {
                  category_title: category[i].category_title,
                  category_icon_url: category[i].category_icon_url,
                  id: category[i].id,
                  category_name: category[i].category_name,
                  count: category[i].count
                }
              ) 
              
            }

            for (let i in this.category) {
              this.total_category += parseInt(this.category[i].count)
              
            }

            this.process.category = false
            this.category[0].count = this.total_category
          }
        })
      },
      async fetch () {
        this.process.load = true

        let params = {
          title: (this.$route.params.slug.split('-').join(' ').split('=').join('-').split('>').join('/'))
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            this.detail = res.results.data[0]
            this.fetchDetail()
            if (res.results.data.length < 1 || res.results.data.length > 1) {
              this.fetchDetailbyID()
            }
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      async fetchDetailbyID () {
        this.process.load = true

        let params = {
          id: this.$route.query.q
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false
            
          if (res.status === 200) {
            this.detail = res.results.data[0]
            this.fetchDetail()
            if (res.results.data.length < 1 || res.results.data.length > 1) {
              this.process.load = false
            }
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      async fetchDetail () {
        this.process.load = true

        let params = {
          id: this.detail.id
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          this.process.load = false
            
          if (res.status === 200) {
            this.detail = res.results.data[0]
            this.detail.meta_json = JSON.parse(res.results.data[0].meta_json)
            if (this.detail.image_url_json,length > 0) {
              this.detail.image_url_json = JSON.parse(this.detail.image_url_json)
              this.detail.image_url_json = this.detail.image_url_json.map(item => {
                const container = {};
  
                container.src = item
  
                // this.category = container;
                return container
              })
            }
            // this.detail = res.results.data
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      downloadFile(fileURL, fileName) {
        const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.download = fileName;

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
        // fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
        //   .then(res => res.blob())
        //   .then(res => {
        //     // console.log(this.getFile(fileName).split('/')[5])
        //     const aElement = document.createElement('a');
        //     aElement.setAttribute('download', fileName);
        //     const href = URL.createObjectURL(res);
        //     aElement.href = href;
        //     aElement.setAttribute('target', '_blank');
        //     aElement.click();
        //     URL.revokeObjectURL(href);
        //   });
      },
      // async fetchDetail () {
      //   this.process.load = true

      //   let params = {
      //     id: this.$route.params.slug
      //   }

      //   await this.$axios.$get(`${process.env.API}sites/article/detail`, { params })
      //   .then(response => {
      //     let res = response

      //     this.process.load = false
            
      //     if (res.status === 200) {
      //       this.detail = res.results.data
      //       this.detail.meta_json = JSON.parse(res.results.data.meta_json)
      //     }
      //   })
      // }
    }
  }
</script>